<template>
  <div class="
    d-flex 
    flex-column
    px-4 content-height flex-grow-1 ">
    <div class="d-flex flex-column cart-header">
      <div class="mb-3 d-flex justify-content-between">
        <div class="d-flex">
          <span class="font-nunito font-18 font-700 my-auto">
            Keranjang Belanja
          </span>
          <div class="counter font-700">{{ Object.keys(cart).length ? productAvailable.length : 0 }}</div>
        </div>
        <el-dropdown trigger="click" @command="handleCommand">
          <div class="d-flex gap-2 align-items-center">
            <span class="font-nunito font-14 font-700">
              {{ Object.keys(customerSelected).length ? customerSelected.name : 'Guest User' }}
            </span>
            <img class="profile-picture"
              :src="Object.keys(customerSelected).length ? imageUser(customerSelected.image, customerSelected.gender) : require('@/assets/illustration/empty-profile-male.svg')"
              alt="">
            <img src="@/assets/icons/arrow-down-black.svg" alt="">
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item data-bs-toggle="modal" data-bs-target="#popupCustomer">Ganti User</el-dropdown-item>
              <el-dropdown-item data-bs-toggle="modal" data-bs-target="#customerHistory"
                v-if="customerSelected.phone_number != 111000">Riwayat Pembelian</el-dropdown-item>
              <el-dropdown-item command="deleteUser" v-if="customerSelected.phone_number != 111000">Hapus
                User</el-dropdown-item>
            </el-dropdown-menu>
          </div>
        </el-dropdown>
      </div>
      <div class="search-element d-flex ">
        <el-input v-model="orderReference" @keyup.native="orderSearch()"
          placeholder="Cari keranjang pelanggan dengan No. Telp" class="search-grey flex-grow-1">
          <img slot="prefix" class="icon-search" src="@/assets/icons/icon-search.svg" alt="Icon Search">
        </el-input>
        <button class="
          d-flex align-items-center justify-content-center
          btn 
          btn-outline-blue
          font-nunito font-12 font-700
          action-button-draft
          " :disabled="!productAvailable.length" @click="addDraft">
          <IconPlus />
          Draft
        </button>
        <button class="
          d-flex align-items-center justify-content-center
          btn 
          btn-outline-blue
          font-nunito font-12 font-700
          action-button-draft
          " :disabled="!productAvailable.length" @click="handleCurrentPrint">

          <IconPrint />
          Print
        </button>

      </div>
    </div>
    <div class="flex-fill d-flex flex-column position-relative">
      <cart-list v-if="productAvailable.length" />
      <div class="m-auto d-flex flex-column justify-content-between align-items-center" v-else>
        <img src="@/assets/illustration/empty-cart-illustration.svg" class="w-100 empty-illustration p-5 pb-2"
          alt="Illustration Empty">
        <span class="font-roboto font-400 font-16">Belum ada produk di keranjang belanja</span>
      </div>
      <div class="cart-footer">
        <cart-summary v-if="productAvailable.length" class="mt-auto" />
        <cart-navigation />
      </div>
    </div>
  </div>
</template>

<script>
import IhsMixin from '@/mixins/ihs.mixins.js'

import CartList from '@/components/cart/partials/CartList.vue'
import CartSummary from '@/components/cart/partials/CartSummary.vue';
import CartNavigation from '@/components/cart/partials/CartNavigation.vue'
import IconPrint from '@/components/icons/IconPrint.vue'
import IconPlus from '@/components/icons/IconPlus.vue'

import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'

export default {
  mixins: [IhsMixin],
  components: {
    CartList,
    CartSummary,
    CartNavigation,
    IconPrint,
    IconPlus
  },
  data() {
    return {
      orderReference: '',
    }
  },
  computed: {
    ...mapState('cart', ['cart', 'cartDraft', 'cartPayment', 'extraValue']),
    ...mapState('user', ['customerSelected', 'calculatedPoint']),
    ...mapState('payment', ['usePoint']),
    ...mapGetters('cart', ['productAvailable'])
  },
  methods: {
    ...mapActions('cart', ['getCartbyQr', 'getCart', 'sendAdditionalCost', 'emptyCart', 'useVoucher']),
    ...mapActions('user', ['getCustomer']),
    ...mapActions('payment', ['setPaymentUsePoint']),
    ...mapMutations('cart', ['DELETE_CART_PAYMENT', 'SET_CART_EXTRA_VALUE', 'SET_CART_DRAFT']),
    ...mapMutations('user', ['SET_SELECTED_CUSTOMER']),
    ...mapMutations('payment', ['SET_USE_POINT']),
    ...mapMutations('voucher', ['SET_PREVIEW_VOUCHER_SELECTED']),
    orderSearch() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        if (this.orderReference.length !== 0) {
          let phoneNumber = ''
          this.orderReference.charAt(0) === '0' ? phoneNumber = this.orderReference.replace(/^0+/, '') : phoneNumber = this.orderReference
          this.DELETE_CART_PAYMENT()

          this.getCart({ id: phoneNumber }).then(() => {
            this.SET_USE_POINT(false)
            this.getCustomer({ phone_number: phoneNumber })
            this.setPaymentUsePoint(0)
            this.SET_CART_EXTRA_VALUE(0)
          }
          ).catch((error) => {
            if (error.response.data.status_code === 404) {
              this.$message({
                showClose: true,
                customClass: "error-message",
                message: "No. Telp tidak terdaftar",
                type: "error"
              });
            }
          })
        }
      }, 1000);
    },
    getCartBefore() {
      this.getCart({
        id: this.cartPayment.phone_number, params: {
          init: false
        }
      }).then(
        this.getCustomer({ phone_number: this.cartPayment.phone_number })
      )
    },
    async checkLastUseAdditionalCost() {
      if (this.extraValue) {
        await this.sendAdditionalCost(this.extraValue)
      }
    },
    checkLastUsePoint() {
      if (this.usePoint) {
        this.setPaymentUsePoint(this.calculatedPoint)

      } else {
        this.setPaymentUsePoint(0)

      }
    },
    getSessionAccount() {
      this.getCart({ id: this.customerSelected.phone_number }).then(
        this.getCustomer({ phone_number: this.customerSelected.phone_number })
      )
    },
    getGuestAccount() {
      this.getCustomer({ phone_number: 111000 }).then(() => {
        this.getCart({ id: 111000 })
      })
    },
    handleCommand(command) {
      if (command === 'deleteUser') {
        this.SET_USE_POINT(false)
        this.SET_CART_EXTRA_VALUE(0)
        this.setPaymentUsePoint(0)
        this.DELETE_CART_PAYMENT()
        this.getCustomer({ phone_number: 111000 }).then(() => {
          this.getCart({ id: 111000 })
        })
      }
    },
    async addDraft() {
      this.SET_CART_DRAFT(
        [
          ...this.cartDraft,
          {
            date: new Date(),
            id: new Date() + this.customerSelected.phone_number,
            cart: this.cart,
            user: this.customerSelected,
            viewMore: false,
            vouchers: this.cart.vouchers,
            extraValue: this.extraValue
          }
        ]
      )
      this.$message({
        showClose: true,
        customClass: "success-message",
        message: "Draft berhasil ditambahkan",
        type: "success"
      });
      await this.emptyCart({ data: { phone_number: 111000 } })
      this.SET_CART_EXTRA_VALUE(0)
      this.SET_PREVIEW_VOUCHER_SELECTED({})
      await this.useVoucher({
        data: {
          voucher_code: '',
          phone_number: this.customerSelected.phone_number
        }
      })
      await this.getCustomer({ phone_number: 111000 }).then(() => {
        this.getCart({ id: 111000 })
      })
    },
    handleCurrentPrint() {
      localStorage.setItem('order', JSON.stringify({
        products: this.cart.products,
        user: this.customerSelected,
        payments: [],
        vouchers: this.cart.vouchers
      }))

      this.$router.push('/order-success')
    }
  },
  async created() {
    if (Object.keys(this.cartPayment).length) {
      this.getCartBefore()
      this.checkLastUseAdditionalCost()
      await this.checkLastUsePoint()
    }
    else if (Object.keys(this.customerSelected).length) {
      this.getSessionAccount()
      this.checkLastUseAdditionalCost()
      await this.checkLastUsePoint()
    }
    else {
      this.getGuestAccount()
    }
  }
}
</script>

<style lang="scss" scoped>
.cart-footer {
  bottom: 0;
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 100;
}

.search-element {
  gap: 10px;
}

.action-button-draft {
  word-break: keep-all;
  height: 40px;
  padding: 8px 16px;
  gap: 10px;
}
</style>