<template>
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M16.3359 9.16658H11.3359V4.16659C11.3359 3.94557 11.2481 3.73361 11.0919 3.57733C10.9356 3.42105 10.7236 3.33325 10.5026 3.33325C10.2816 3.33325 10.0696 3.42105 9.91335 3.57733C9.75707 3.73361 9.66927 3.94557 9.66927 4.16659V9.16658H4.66927C4.44826 9.16658 4.2363 9.25438 4.08002 9.41066C3.92374 9.56694 3.83594 9.7789 3.83594 9.99992C3.83594 10.2209 3.92374 10.4329 4.08002 10.5892C4.2363 10.7455 4.44826 10.8333 4.66927 10.8333H9.66927V15.8333C9.66927 16.0543 9.75707 16.2662 9.91335 16.4225C10.0696 16.5788 10.2816 16.6666 10.5026 16.6666C10.7236 16.6666 10.9356 16.5788 11.0919 16.4225C11.2481 16.2662 11.3359 16.0543 11.3359 15.8333V10.8333H16.3359C16.557 10.8333 16.7689 10.7455 16.9252 10.5892C17.0815 10.4329 17.1693 10.2209 17.1693 9.99992C17.1693 9.7789 17.0815 9.56694 16.9252 9.41066C16.7689 9.25438 16.557 9.16658 16.3359 9.16658Z"
            :fill="color" />
    </svg>
</template>
<script>
export default {
    props: {
        color: {
            type: String,
            default: '#006cb5'
        }
    },
}
</script>