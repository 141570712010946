<template>
	<div
		class="modal fade"
		id="customerHistory"
		tabindex="-1"
		aria-labelledby="exampleModalLabel"
		aria-hidden="true"
		data-bs-backdrop="static"
	>
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content  py-3 px-2">
				<button
					type="button"
					ref="closeModalref"
					class="btn-close d-none"
					data-bs-dismiss="modal"
					aria-label="Close"
				></button>

				<div
					class="modal-body"
					v-if="Object.keys(customerSelected).length"
				>
					<div class="d-flex gap-3">
						<img
							class="profile-picture md"
							:src="Object.keys(customerSelected).length ? imageUser(customerSelected.image, customerSelected.gender) : require('@/assets/illustration/empty-profile-male.svg')"
							alt=""
						>
						<div class="d-flex flex-column">
							<span class="font-700 font-16 font-nunito">{{customerSelected.name}}</span>
							<span class="font-400 font-14 font-roboto">0{{customerSelected.phone_number}}</span>
						</div>
						<button
							type="button"
							@click="resetSearch()"
							class="btn-close ms-auto"
							data-bs-dismiss="modal"
							aria-label="Close"
						></button>

					</div>
					<el-form
						:inline="true"
						:label-position="'top'"
						:model="form"
						class="d-flex my-3 gap-3"
					>
						<el-form-item class="w-25 mb-2">
							<slot name="label">
								<span>Tanggal Mulai</span>
							</slot>
							<el-date-picker
								v-model="form.after"
								placeholder="dd/mm/yy"
								:picker-options="{ disabledDate: (time) => disabledStartDate(time, form.before) }"
								format="yyyy-MM-dd"
								value-format="yyyy-MM-dd"
							></el-date-picker>
						</el-form-item>
						<el-form-item class="w-25 mb-2">
							<slot name="label">
								<span>Tanggal Berakhir</span>
							</slot>
							<el-date-picker
								type="date"
								v-model="form.before"
								placeholder="dd/mm/yy"
								:picker-options="{ disabledDate: (time) => disabledEndDate(time, form.after) }"
								format="yyyy-MM-dd"
								value-format="yyyy-MM-dd"
							></el-date-picker>
						</el-form-item>
						<el-form-item class="w-25 mb-2">
							<slot name="label">
								<span>Cari Produk</span>
							</slot>
							<el-input
								v-model="form.search"
								class="ps-1"
							>
								<img
									slot="prefix"
									class="icon-search ms-2"
									src="@/assets/icons/icon-search.svg"
									alt="Icon Search"
								>
							</el-input>
						</el-form-item>
						<el-form-item class="align-self-end mb-2">
							<button
								type="button"
								class="button submit-btn button--primary button--sm w-100 py-0 px-3 my-auto"
								@click="search"
							>Cari Produk</button>

							<!-- <el-button type="primary" @click="onSubmit">Query</el-button> -->
						</el-form-item>
					</el-form>
					<el-table
						:data="customerHistory.orders"
						style="width: 100%;"
						height="465"
						v-loading="loading"
						empty-text="Tidak ada data"
						v-el-table-infinite-scroll="submit"
						ref="historyCustomerTable"
					>
						<el-table-column
							class="text-black"
							prop="invoice_number"
							label="No Invoice"
							width="180"
						>
						</el-table-column>
						<el-table-column
							label="Produk Yang Dibeli"
							width="300"
						>
							<template slot-scope="scope">
								<div
									v-for="(product, index) in scope.row.products"
									:key="'product - ' + product.id + ' ' + index"
									class="d-flex flex-column"
									:class="{'mt-2': index > 0}"
								>
									<span>{{product.name}}</span>
									<span
										v-if="product.type === 'combination'"
										class="color-grey-70 font-12"
									>{{product.detail_name}}</span>
								</div>
							</template>
						</el-table-column>
						<el-table-column
							label="Qty"
							class-name="valign-base"
						>
							<template slot-scope="scope">
								<div
									v-for="(product, index) in scope.row.products"
									:key="'qty - ' +product.id + ' ' + index"
									class="d-flex flex-column justify-content-between"
									:class="{'mt-2': index > 0}"
								>
									<span>{{product.quantity}}
									</span>
								</div>
							</template>
						</el-table-column>
						<el-table-column
							label="Harga"
							class-name="valign-base"
						>
							<template slot-scope="scope">
								<div
									v-for="(product, index) in scope.row.products"
									:key="'price-' +product.id + index"
									class="d-flex flex-column justify-content-between"
									:class="{'mt-2': index > 0}"
								>
									<span>{{formatRupiah(product.quantity * product.price , 'Rp. ')}}</span>
								</div>
							</template>
						</el-table-column>
						<el-table-column>
							<template slot-scope="scope">

								<button
									class="button-add text-primary bg-primary-light font-24 d-flex  justify-content-center "
									@click="add(scope.row.products)"
								>+</button>
							</template>
						</el-table-column>
					</el-table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import elTableInfiniteScroll from 'el-table-infinite-scroll';

import IhsMixin from "@/mixins/ihs.mixins.js";

import { mapState, mapActions } from "vuex";
	
export default {
	directives: {
		'el-table-infinite-scroll': elTableInfiniteScroll
	},
	mixins: [IhsMixin],
	data() {
		return {
			loading: false,
			current_page: 0,
			form: {
				search: "",
				before: "",
				after: ""
			}
		};
	},
	computed: {
		...mapState("user", ["customerSelected", "customerHistory"])
	},
	methods: {
		...mapActions("cart", ["addCart", "getCart"]),
		...mapActions("user", ["getCustomerHistory"]),
		disabledEndDate(date, departureDate) {
			if (departureDate) {
				return !this.$moment(date).isBetween(
					this.$moment(departureDate),
					Date.now()
				);
			} else {
				return this.$moment(date) > Date.now();
			}
		},
		disabledStartDate(date, before) {
			if (before) {
				return this.$moment(date) >= this.$moment(before);
			} else {
				return this.$moment(date) > Date.now();
			}
		},
		resetSearch() {
			this.form = {
				search: "",
				before: "",
				after: ""
			};
		},
		async search() {
			this.current_page = 0
			this.$refs.historyCustomerTable.bodyWrapper.scrollTop = 0;
			await this.submit()
		},
		async submit() {
			try {
				this.loading = true
				this.current_page++
				if(this.current_page <= this.customerHistory.last_page || !this.customerHistory.last_page) {
					const payload = {
						phone_number: this.customerSelected.phone_number,
						params: {
							...this.form,
							page: this.current_page,
							perPage: 8
						}
					};

					await this.getCustomerHistory(payload);
				}

				this.loading = false
				
			} catch (error) {
				this.loading = false
				this.$message({
					showClose: true,
					message: error.message,
					type: "error"
				});
			}
		},
		async add(products) {
			const getAvailableProducts = products.filter(product => {
				return product.stock > 0
			})
			const historyProducts = getAvailableProducts.map(product => {
				return {
					product_detail_id: product.product_detail_id,
					quantity: product.quantity
				};
			});
			const payload = {
				data: {
					products: historyProducts,
					phone_number: this.customerSelected.phone_number
				},
			};

			const response = await this.addCart(payload);

			if (response.data.status_code === 200) {
				await this.$message({
					message: "Produk Berhasil Ditambahkan Ke Keranjang",
					type: "success"
				});
				if(products.length !== getAvailableProducts.length) {
					if (!getAvailableProducts.length) {
						await this.$message({
							message: 'Seluruh produk pada transaksi ini sudah habis',
							type: "warning"
						});
					} else {
						await this.$message({
							message: products.length - getAvailableProducts.length + ' Dari ' + products.length + ' Produk sudah habis',
							type: "warning"
						});
					}
				
				}
					
				const payload = {
					query: {
						init: true
					},
					id: this.customerSelected.phone_number
				};
				this.getCart(payload);
			}

			this.$refs.closeModalref.click();
		},
		getOrderQuantity(products) {
			let total = 0;
			products.forEach(product => {
				total += product.quantity;
			});

			return total;
		}
	},
	mounted () {
		const _this = this

		let customerHistoryModal = document.getElementById('customerHistory')
		customerHistoryModal.addEventListener('show.bs.modal', async function () {
			await _this.search()
		})
	}
};
</script>
<style lang="scss">
.valign-base {
	vertical-align: baseline !important;
}
</style>
<style lang="scss" scoped>
.button-add {
	width: 30px;
	height: 30px;
	border-radius: 4px;
	border: 1px solid #006CB5;
	vertical-align: middle;
}
.modal-dialog {
	width: 870px;
	max-width: unset !important;
}

.modal-content {
	height: 700px !important ;
}
.submit-btn {
	height: 40px !important;
}
</style>