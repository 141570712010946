<template>
  <button class="d-flex flex-grow-1 voucher-button w-100" @click="openModal">
    <img src="@/assets/icons/voucher-icon.svg" alt="voucher">
    <div class="d-flex flex-column align-items-start">
      <template v-if="onPreview && Object.keys(previewVoucher).length">
        <span class="font-nunito font-12 font-md-14">
          1 Voucher dipilih
        </span>
      </template>
      <!-- <template v-else-if="!onPreview && Object.keys(voucher).length">
          <span class="font-nunito font-12 font-md-14 font-700">
            1 Voucher dipilih
          </span>
        </template> -->
      <template v-if="onPreview ? Object.keys(previewVoucher).length : Object.keys(voucher).length">
        <ul v-if="cart.vouchers.length" class="d-flex list-unstyled mb-0 pb-0 color-primary font-700">
          <li>{{ voucher.code }}</li>

        </ul>
        <span v-else class="font-nunito font-12 font-md-14">Kamu Hemat <b class="color-primary"> {{
          formatRupiah(voucherAmmount) }} </b></span>
      </template>
      <template v-else>
        <span class="font-nunito font-12 font-md-14 font-700">
          Pilih Voucher
        </span>
      </template>
    </div>
    <button @click.prevent.stop="(event) => handleClearVoucher(event)" class="my-auto ms-auto bg-transparent border-0"
      v-if="onPreview ? Object.keys(previewVoucher).length : Object.keys(voucher).length">
      <img src="@/assets/icons/icon-close.svg" alt="">

    </button>
    <button class=" bg-transparent border-0 my-auto ms-auto" v-else>

      <img src="@/assets/icons/right-arrow-primary-icon.svg" alt="">

    </button>
  </button>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';

import IhsMixin from '@/mixins/ihs.mixins.js'
import { Modal } from 'bootstrap';

export default {
  mixins: [IhsMixin],
  props: {
    onPreview: {
      type: Boolean,
      default: false
    },
    previewVoucher: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
      voucherListModal: ''
    }
  },
  computed: {
    ...mapState('cart', ['cart', 'voucher']),
    ...mapGetters('cart', ['subtotal']),
    ...mapState('user', ['customerSelected']),
    voucherAmmount() {
      if (Object.keys(this.previewVoucher).length) {
        if (this.previewVoucher.reduction_type === "percent") {
          let discount = (this.previewVoucher.value / 100) * this.subtotal
          return discount
        } else {
          return this.previewVoucher.value
        }
      } else {
        return 0
      }
    }
  },
  mounted() {
  },
  methods: {
    ...mapActions('cart', ['useVoucher', 'getCart']),
    ...mapMutations('voucher', ['SET_PREVIEW_VOUCHER_SELECTED']),
    openModal() {
      const a = Modal.getOrCreateInstance(document.getElementById('voucherList'))
      a.show()
    },
    handleClearVoucher(event) {
      event.stopPropagation()
      event.preventDefault()
      this.onPreview ? this.$emit('delete-voucher') : this.handleDeleteVoucher()

    },
    async handleDeleteVoucher() {
      this.SET_PREVIEW_VOUCHER_SELECTED({})

      try {

        await this.useVoucher({
          data: {
            voucher_code: '',
            phone_number: this.customerSelected.phone_number
          }
        })
          .then(response => {
            if (!response.data.status_code === 200) return

            this.getCart({ id: this.customerSelected.phone_number }).then(() => {
              this.$message({
                showClose: true,
                message: 'Berhasil menghapus voucher',
                type: 'success'
              })
            })
          })


      } catch (error) {
        console.log(error)
      }
    },

  }
}
</script>

<style lang="scss">
.voucher-button {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 10px 14px;
  background-color: white;
  border: 1px solid $primary;
  border-radius: 8px;
  margin-bottom: 16px;

  &.active {
    background-color: $primary-light;
  }
}
</style>