<template>
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M5.5 17.9999H9.74C9.87161 18.0007 10.0021 17.9755 10.1239 17.9257C10.2457 17.8759 10.3566 17.8026 10.45 17.7099L17.37 10.7799L20.21 7.99994C20.3037 7.90698 20.3781 7.79637 20.4289 7.67452C20.4797 7.55266 20.5058 7.42195 20.5058 7.28994C20.5058 7.15793 20.4797 7.02722 20.4289 6.90536C20.3781 6.7835 20.3037 6.6729 20.21 6.57994L15.97 2.28994C15.877 2.19621 15.7664 2.12182 15.6446 2.07105C15.5227 2.02028 15.392 1.99414 15.26 1.99414C15.128 1.99414 14.9973 2.02028 14.8754 2.07105C14.7536 2.12182 14.643 2.19621 14.55 2.28994L11.73 5.11994L4.79 12.0499C4.69732 12.1434 4.62399 12.2542 4.57423 12.376C4.52446 12.4979 4.49924 12.6283 4.5 12.7599V16.9999C4.5 17.2652 4.60536 17.5195 4.79289 17.707C4.98043 17.8946 5.23478 17.9999 5.5 17.9999ZM15.26 4.40994L18.09 7.23994L16.67 8.65994L13.84 5.82994L15.26 4.40994ZM6.5 13.1699L12.43 7.23994L15.26 10.0699L9.33 15.9999H6.5V13.1699ZM21.5 19.9999H3.5C3.23478 19.9999 2.98043 20.1053 2.79289 20.2928C2.60536 20.4804 2.5 20.7347 2.5 20.9999C2.5 21.2652 2.60536 21.5195 2.79289 21.707C2.98043 21.8946 3.23478 21.9999 3.5 21.9999H21.5C21.7652 21.9999 22.0196 21.8946 22.2071 21.707C22.3946 21.5195 22.5 21.2652 22.5 20.9999C22.5 20.7347 22.3946 20.4804 22.2071 20.2928C22.0196 20.1053 21.7652 19.9999 21.5 19.9999Z"
            :fill="color" />
    </svg>
</template>
<script>
export default {
    props: {
        color: {
            type: String,
            default: '#006cb5'
        }
    },
}
</script>