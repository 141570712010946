<template>
  <div class="modal fade" id="cartDraft" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
    data-bs-backdrop="static">
    <div class="modal-dialog modal-dialog-centered overflow-hidden">
      <div class="modal-content px-2 overflow-hidden">
        <button type="button" ref="closeModalref" class="btn-close d-none" data-bs-dismiss="modal"
          aria-label="Close"></button>
        <div class="modal-header">
          <span class="
						font-nunito 
						font-18 
						font-700">
            Draft Keranjang
          </span>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body overflow-scroll">
          <div class="d-flex gap-3 justify-content-between">
            <span class="font-14"> <b>{{ cartDraft.length }}</b> Draft Tersedia</span>
            <el-dropdown trigger="click" @command="changeSort">
              <span class="font-12 font-roboto font-400 color-black">
                Urut Berdasarkan
                <span class="font-700">{{ selectedSort.name }}</span>
                <i class="el-icon-arrow-down el-icon--right font-700 font-12"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="sort" v-for="sort in listSorting" :key="sort.slug">{{ sort.name
                  }}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div class="draft-list d-flex flex-column mt-3 h-100">
            <div v-if="!cartDraft.length" class="h-100 text-center d-flex align-items-center justify-content-center">
              <span>
                Belum Ada Draft Yang Disimpan
              </span>
            </div>
            <div class="draft-item" v-for="draft in sortedByDate" :key="draft.id">
              <div class="draft-item-header">
                <span>Tersimpan: {{ formatDate(draft.date) }} WIB</span>
                <button @click="handleDeleteDraft(draft.id)">
                  <img src="@/assets/icons/trash-icon.svg" alt="delete">
                </button>
              </div>
              <div class="draft-item-body">
                <div class="d-flex gap-3 align-items-center justify-content-between">
                  <div class="d-flex gap-1 align-items-center">
                    <img class="profile-picture md" :src="draft.user.image_thumbnail" alt="" />
                    <span class="font-14 font-roboto">{{
                      draft.user.name
                    }}</span>
                    <span class="font-14 font-roboto color-blue-dark-50 font-700 ">({{ draft.cart.products.length }}
                      produk)</span>
                  </div>

                  <span class="font-14 font-roboto font-500">
                    {{ formatRupiah(totalPrice(draft.cart.products), "Rp.") }}
                  </span>
                </div>
                <div class="d-flex flex-column font-14 font-roboto gap-1 mt-2">
                  <span
                    v-for="product in draft.cart.products.slice(0, draft.cart.products.length > 3 && !draft.viewMore ? 3 : draft.cart.products.length)"
                    :key="product.id">
                    {{ product.name }} x{{ product.quantity }}
                  </span>
                  <span v-if="draft.cart.products.length > 3 && !draft.viewMore" class="font-700 color-blue-dark-50"
                    @click="handleShowMore(draft.id)">Lihat Lebih banyak</span>
                </div>
              </div>
              <div class="d-flex">
                <div class="flex-grow-1 pl-4 pr-2">
                  <button
                    class="btn-draft btn-draft--left btn btn-outline-primary px-1 font-nunito font-14 font-700 py-2 w-100"
                    @click="handlePrintReceipt(draft)">
                    Cetak Struk
                  </button>
                </div>
                <div class="flex-grow-1 pr-4 pl-2">
                  <button
                    class="btn-draft btn-draft--right btn btn-primary font-nunito font-14 font-700 py-2 w-100 px-1 text-white"
                    @click="handleAddCheckout(draft)">
                    Lanjut Checkout
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import elTableInfiniteScroll from "el-table-infinite-scroll";

import IhsMixin from "@/mixins/ihs.mixins.js";

import { mapState, mapActions, mapMutations } from "vuex";

export default {
  directives: {
    "el-table-infinite-scroll": elTableInfiniteScroll,
  },
  mixins: [IhsMixin],
  data() {
    return {
      loading: false,
      current_page: 0,
      selectedSort: {
        name: "Terbaru",
        type: "default",
      },
      form: {
        search: "",
        before: "",
        after: "",
      },
      listSorting: [
        {
          name: "Terbaru",
          type: "default",
        },
        {
          name: "Terlama",
          type: "oldest",
        },
      ],
    };
  },
  computed: {
    ...mapState("user", ["customerSelected", "customerHistory"]),
    ...mapState("cart", ["cartDraft"]),
    sortedByDate() {
      const sortedDrafts = [...this.cartDraft]; // Create a copy of cartDraft
      return sortedDrafts.sort((a, b) => {
        if (this.selectedSort.type === "default") {
          return new Date(b.date) - new Date(a.date);
        } else {
          return new Date(a.date) - new Date(b.date);
        }
      });
    }
  },
  methods: {
    ...mapActions("cart", ["addCart", "getCart", "emptyCart", "addCartMultiple", 'useVoucher']),
    ...mapActions("user", ["getCustomerHistory", "getCustomer"]),
    ...mapMutations("cart", ["SET_CART_DRAFT", 'SET_CART_EXTRA_VALUE']),

    async handleDeleteDraft(id) {
      const updatedDrafts = this.cartDraft.filter((draft) => draft.id !== id);
      this.SET_CART_DRAFT(updatedDrafts);

    },
    async handleAddCheckout(draft) {
      await this.emptyCart({
        data: {
          phone_number: draft.user.phone_number,
        }
      });
      this.SET_CART_EXTRA_VALUE(draft.extraValue)
      const payload = {
        id: draft.user.phone_number,
        data: {
          product_details: draft.cart.products.map((product) => {
            return {
              product_detail_id: product.product_detail_id,
              quantity: product.quantity,
            };
          }),
        },

      }
      await this.addCartMultiple(payload);
      if (draft.vouchers.length > 0) {
        await this.useVoucher({
          data: {
            voucher_code: draft.vouchers[0].code,
            phone_number: draft.user.phone_number
          }
        })
      }
      await this.getCustomer({ phone_number: draft.user.phone_number }).then(() => {
        this.getCart({ id: draft.user.phone_number })
      })


      this.handleDeleteDraft(draft.id);
    },

    async handleShowMore(id) {
      const updatedDrafts = this.cartDraft.map((draft) => {
        if (draft.id === id) {
          return {
            ...draft,
            viewMore: !draft.viewMore,
          }
        }
        return draft;
      });


      this.SET_CART_DRAFT(updatedDrafts);
    },

    changeSort(sort) {
      this.selectedSort = sort;

    },
    totalPrice(products) {
      return products.reduce((acc, product) => {
        return acc + product.price * product.quantity;
      }, 0);
    },


    async handlePrintReceipt(draft) {
      localStorage.setItem('order', JSON.stringify({
        products: draft.cart.products,
        user: draft.user,
        payments: [],
        vouchers: draft.cart.vouchers
      }))

      this.$router.push('/order-success')
    },

    formatDate(date) {
      const options = {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      };
      const formatter = new Intl.DateTimeFormat('id-ID', options);
      const formattedDate = formatter.format(new Date(date));
      return formattedDate.replace('pukul', ',');
    }

  }
};
</script>

<style lang="scss">
.valign-base {
  vertical-align: baseline !important;
}
</style>

<style lang="scss" scoped>
.button-add {
  width: 30px;
  height: 30px;
  border-radius: 4px;
  border: 1px solid #29c04a;
  vertical-align: middle;
}

.modal-dialog {
  width: 870px;
  max-width: unset !important;
}

.modal-content {
  height: 700px !important;
}

.submit-btn {
  height: 40px !important;
}

.draft-list {
  gap: 16px;
}

.draft-item {
  border: 1px solid #d9d9da;
  border-radius: 9px;

  &-header {
    padding: 16px;
    border-bottom: 1px solid #d9d9da;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-body {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
}

.btn-draft {
  height: 48px;

  &--left {
    border-radius: 0 0 0 10px;
  }

  &--right {
    border-radius: 0 0 10px 0;
  }
}
</style>
